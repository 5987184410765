import { DsmdKeyItem, InstallationStatus, InstallationStatusValue } from '../domain/DTO'
import * as React from 'react'
import { ReactComponent as NoWifiIcon } from '@tomra/design-system/src/config/icons/no-wifi.svg'
import { ReactComponent as CheckmarkIcon } from '@tomra/design-system/src/config/icons/checkmark.svg'
import { ReactComponent as CloseIcon } from '@tomra/design-system/src/config/icons/close.svg'
import { ReactComponent as TimeIcon } from '@tomra/design-system/src/config/icons/time.svg'
import colors from '../styles/colors'
import { installationStatus } from '../lib/InstallationStatus'
import { Link } from 'react-router-dom'

interface Props {
  dsmdKeyItem: DsmdKeyItem
}

export const TableRow: React.FC<Props> = ({ dsmdKeyItem }) => {
  const status = (status?: InstallationStatus) => {
    if (!status) return 'N/A'
    switch (status) {
      case InstallationStatusValue.INITIALIZED:
      case InstallationStatusValue.PROCESSING:
        return <TimeIcon title={'in progress'} width="1rem" height="1rem" />
      case InstallationStatusValue.OK:
        return <CheckmarkIcon title={'OK'} width="1rem" height="1rem" color="var(--colors-green)" />
      case InstallationStatusValue.NOT_OK:
        return <CloseIcon title={'Not OK'} width="1rem" height="1rem" color="var(--colors-red)" />
      default:
        return status
    }
  }

  const reason = (errorDetails?: string) => {
    if (errorDetails?.includes('NOT_CONNECTED')) {
      return (
        <div style={{ display: 'flex' }}>
          <NoWifiIcon title={'Not connected'} width="1rem" height="1rem" color="var(--colors-red)" />
          <p style={{ marginLeft: '.5em' }}>Not connected</p>
        </div>
      )
    }
    if (errorDetails) {
      return errorDetails
    }
    if (installationStatus(dsmdKeyItem) === 1) {
      return (
        <div style={{ display: 'flex' }}>
          <TimeIcon title={'Waiting'} width="1rem" height="1rem" color="var(--colors-blue)" />
          <p style={{ marginLeft: '.5em' }}>Waiting for RVM...</p>
        </div>
      )
    }
    return ''
  }

  const rowColor = () => {
    switch (installationStatus(dsmdKeyItem)) {
      case 0:
        return colors.LIGHT_RED
      case 1:
        return colors.LIGHT_YELLOW
      case 2:
        return colors.LIGHT_GREEN
    }
  }

  return (
    <tr key={`${dsmdKeyItem.installationId}`} style={{ backgroundColor: rowColor() }}>
      <td>
        <Link to={`/installations/${dsmdKeyItem.serialNumber}`} className="link ml-sm>">
          {dsmdKeyItem.serialNumber}
        </Link>
      </td>
      <td>{status(dsmdKeyItem.aleCredentials?.status)}</td>
      <td>{status(dsmdKeyItem.dpgCredentials?.status)}</td>
      <td>{new Date(dsmdKeyItem.updatedAt).toLocaleString()}</td>
      <td>{reason(dsmdKeyItem.aleCredentials?.errorDetails)}</td>
    </tr>
  )
}
