import React from 'react'
import styled from 'styled-components'
import { colors } from '../styles'
import { ArrowDownIcon } from '../icons'
import { ArrowUpIcon } from '../icons/ArrowUpIcon'

type SortableHeaderContentProps = {
  name: string
  onClick?: () => void
  active_sorting: boolean
  sort_direction: 'ASC' | 'DESC'
  className?: string
}

type SortableHeaderProps = SortableHeaderContentProps & {
  className?: string
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

const FatText = styled.div`
  font-weight: 800;
  color: ${colors.PRIMARY_TEXT};
  cursor: pointer;
`

export function SortableHeaderContent({
  name,
  onClick,
  active_sorting,
  sort_direction,
  className,
}: SortableHeaderContentProps) {
  return (
    <HeaderContainer className={className ?? ''}>
      <FatText onClick={onClick}> {name} </FatText>
      {active_sorting && sort_direction === 'ASC' && <ArrowUpIcon color={colors.PRIMARY_TEXT} />}
      {active_sorting && sort_direction === 'DESC' && <ArrowDownIcon color={colors.PRIMARY_TEXT} />}
    </HeaderContainer>
  )
}

export const SortableHeader = ({ name, onClick, active_sorting, sort_direction, className }: SortableHeaderProps) => {
  return (
    <th className={className}>
      <SortableHeaderContent
        name={name}
        onClick={onClick}
        active_sorting={active_sorting}
        sort_direction={sort_direction}
      />
    </th>
  )
}
