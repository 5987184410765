import { TopHeader } from './components/TopHeader'
import { Navigate, Route, Routes } from 'react-router-dom'
import { NotFound } from './NotFound'
import { Installations } from './Pages/Installations'
import { InstallationDetails } from './Pages/InstallationDetails'

type Props = {
  doLogout: () => void
}

export const App = ({ doLogout }: Props) => {
  return (
    <>
      <TopHeader doLogout={doLogout} />
      <div className="px-lg pt-md">
        <Routes>
          <Route path="/installations" element={<Installations />} />
          <Route path="/installations/:id" element={<InstallationDetails />} />
          <Route path="/" element={<Navigate to="/installations?page=1&size=20&sortKey=updated_at&sortDir=DESC" />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  )
}

export default App
