import styled from 'styled-components'

interface Props {
  isLoading: boolean
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const Spinner = styled.div`
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
`

export const LoadingOverlay: React.FC<Props> = ({ isLoading }) => {
  if (!isLoading) return null
  return (
    <Overlay>
      <Spinner />
    </Overlay>
  )
}
