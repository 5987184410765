import App from './App'
import { authentication } from './lib'
import { ENV_NAME, VERSION } from './lib'
import { createRoot } from 'react-dom/client'
import { logError } from './lib/logError'
import { BrowserRouter as Router } from 'react-router-dom'

const container = document.getElementById('root')
const root = createRoot(container!)
if (!root) {
  throw new Error('Could not get #root element')
}

const environment = (
  <div className="container mx-auto">
    {VERSION} | {ENV_NAME}
  </div>
)

authentication.init().then(
  () => {
    root.render(
      <Router>
        <App doLogout={authentication.logout} />
        {environment}
      </Router>
    )
  },
  (err: any) => {
    if (err === 'Unauthenticated') {
      authentication.login()
    } else {
      err
        ? logError(new Error('Authentication failed'), err)
        : logError(new Error('Authentication failed'), new Error('Unknown error'))

      root.render(
        <div className="alert alert-danger">
          Wooopsie, authentication failed for some reason!
          {environment}
        </div>
      )
    }
  }
)
