import { useParams } from 'react-router'
import { TitleContainer } from '../styles/PageLayout'
import colors from '../styles/colors'
import { Link } from 'react-router-dom'
import { DsmdKeyItem, InstallationStatusValue } from '../domain/DTO'
import * as React from 'react'
import { useEffect } from 'react'
import { ArrowRightIcon } from '../icons'
import styled from 'styled-components'
import { API_HOST } from '../lib'
import { authenticatedGet } from '../lib/authenticatedGet'

export const InstallationDetails: React.FC = () => {
  const location = useParams()
  const serialNumber = location['id'] || 'UNKNOWN'

  const [dsmdKeyItem, setDsmdKeyItem] = React.useState<DsmdKeyItem | null>(null)
  useEffect(() => {
    const installationsUrl = `${API_HOST}/v2/key-status/installations/${serialNumber}`
    authenticatedGet(installationsUrl)
      .then((response) => {
        setDsmdKeyItem(response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [serialNumber])

  const StyledTd = styled.td`
    width: 20px;
    max-width: 160px !important;
  `
  const { id } = useParams<{ id: string }>()
  if (!id) {
    throw new Error('Missing id parameter')
  }

  if (!dsmdKeyItem) {
    return (
      <div>
        <TitleContainer>
          <Link to="/installations">
            <h2>Installations</h2>
          </Link>
          <ArrowRightIcon color={colors.PRIMARY} />
          <h2>{`could not find installation`}</h2>
        </TitleContainer>
      </div>
    )
  }

  const aleColor =
    dsmdKeyItem.aleCredentials?.status === InstallationStatusValue.OK ? colors.LIGHT_GREEN : colors.LIGHT_RED
  const dpgColor =
    dsmdKeyItem.dpgCredentials?.status === InstallationStatusValue.OK ? colors.LIGHT_GREEN : colors.LIGHT_RED

  return (
    <div>
      <TitleContainer>
        <Link to="/installations">
          <h2>Installations</h2>
        </Link>
        {<ArrowRightIcon color={colors.PRIMARY} />}
        <h2>{dsmdKeyItem.aleCredentials || dsmdKeyItem.dpgCredentials ? dsmdKeyItem.serialNumber : `no keys found`}</h2>
      </TitleContainer>
      {dsmdKeyItem.aleCredentials && (
        <div className="card" style={{ backgroundColor: aleColor }}>
          <table className="table">
            <tbody>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Key type:</b>
                </StyledTd>
                <StyledTd className={'w-10'}>ALE</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Key id(oneringId):</b>
                </StyledTd>
                <StyledTd>{dsmdKeyItem.aleCredentials.keyId}</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Hash:</b>
                </StyledTd>
                <StyledTd> {dsmdKeyItem.aleCredentials.hash ?? ``}</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Status:</b>
                </StyledTd>
                <StyledTd> {dsmdKeyItem.aleCredentials.status ?? ``}</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Last updated:</b>
                </StyledTd>
                <StyledTd> {new Date(dsmdKeyItem.aleCredentials.updatedAt).toLocaleString() ?? ``}</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Details:</b>
                </StyledTd>
                <StyledTd> {dsmdKeyItem.aleCredentials.errorDetails}</StyledTd>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <br />
      {dsmdKeyItem.dpgCredentials && (
        <div className="card" style={{ backgroundColor: dpgColor }}>
          <table className="table">
            <tbody>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Key type:</b>
                </StyledTd>
                <StyledTd className={'w-10'}>DPG</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Key id(oneringId):</b>
                </StyledTd>
                <StyledTd> {dsmdKeyItem.dpgCredentials.keyId}</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Hash:</b>
                </StyledTd>
                <StyledTd> {dsmdKeyItem.dpgCredentials.hash ?? ``}</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Status:</b>
                </StyledTd>
                <StyledTd> {dsmdKeyItem.dpgCredentials.status ?? ``}</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Last updated:</b>
                </StyledTd>
                <StyledTd> {new Date(dsmdKeyItem.dpgCredentials.updatedAt).toLocaleString() ?? ``}</StyledTd>
              </tr>
              <tr>
                <StyledTd className={'p-md'}>
                  <b>Details:</b>
                </StyledTd>
                <StyledTd> {dsmdKeyItem.dpgCredentials.errorDetails ?? ``}</StyledTd>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
