import { useSearchParams } from 'react-router-dom'
import { API_HOST } from '../lib'
import { LoadingOverlay } from '../components/LoadingOverlay'
import { PagedTableControls } from '@tomra/react-table'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { PagedResponse, SuccessResponse } from '../domain/DTO'
import { SortableHeader } from '../components/TableColumnSort'
import { authenticatedGet } from '../lib/authenticatedGet'
import { TableRow } from '../components/TableRow'
import { ReactComponent as NoWifiIcon } from '@tomra/design-system/src/config/icons/no-wifi.svg'
import { ReactComponent as RefreshIcon } from '@tomra/design-system/src/config/icons/refresh.svg'
import { ReactComponent as CheckmarkIcon } from '@tomra/design-system/src/config/icons/checkmark.svg'
import { ReactComponent as CloseIcon } from '@tomra/design-system/src/config/icons/close.svg'
import { ReactComponent as TimeIcon } from '@tomra/design-system/src/config/icons/time.svg'
import styled from 'styled-components'

const defaultPage = 1
const defaultSize = 20
const defaultSortKey = 'updated_at'

const initResponse: PagedResponse = {
  items: [],
  page: defaultPage,
  total: 0,
  pageSize: defaultSize,
}

export const Installations = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [response, setResponse] = useState<PagedResponse>(initResponse)
  const [isLoading, setIsLoading] = useState(true)
  const [serialNumberInput, setSerialNumberInput] = useState(searchParams.get('serialNumberContains') || '')

  useEffect(() => {
    const page = parseInt(searchParams.get('page') || `${defaultPage}`, 10)
    const size = parseInt(searchParams.get('size') || `${defaultSize}`, 10)
    const sortKey = searchParams.get('sortKey') || 'updated_at'
    const sortDirection = searchParams.get('sortDir') || 'DESC'
    let url = `${API_HOST}/v2/key-status/installations?page=${
      page - 1
    }&size=${size}&sortKey=${sortKey}&sortDir=${sortDirection}`

    const serialNumberSearch = searchParams.get('serialNumberContains')
    if (serialNumberSearch && serialNumberSearch !== '') {
      url = url.concat(`&serialNumberContains=${serialNumberSearch}`)
    }

    setIsLoading(true)
    authenticatedGet(url)
      .then((response: SuccessResponse<PagedResponse>) => {
        setResponse(response.data)
        setIsLoading(false)
      })
      .catch((reason: any) => {
        setIsLoading(false)
        console.error('Failed to fetch installation status', reason)
      })
  }, [searchParams])

  const paginationOptions = [10, 20, 50, 200, 1000]

  const updateSearchParam = (key: string, value: string) => {
    const newParams = new URLSearchParams(searchParams)
    newParams.set(key, value)
    setSearchParams(newParams.toString())
  }

  const setPage = (newPage: number) => {
    updateSearchParam('page', newPage.toString())
  }

  const setPageSize = (newSize: number) => {
    const newParams = new URLSearchParams(searchParams)
    newParams.set('size', newSize.toString())
    newParams.set('page', '1')
    setSearchParams(newParams.toString())
  }

  const setSortKey = (newSortKey: string) => {
    const currentSortKey = searchParams.get('sortKey') || defaultSortKey
    const currentSortDirection = searchParams.get('sortDir') || 'DESC'

    if (currentSortKey === newSortKey) {
      const newSortDirection = currentSortDirection === 'ASC' ? 'DESC' : 'ASC'
      updateSearchParam('sortDir', newSortDirection)
    } else {
      updateSearchParam('sortKey', newSortKey)
    }
  }

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      updateSerialNumberSearch(serialNumberInput)
    }
  }

  const updateSerialNumberSearch = (serialNumber: string) => {
    const newParams = new URLSearchParams(searchParams)
    if (serialNumber === '') {
      newParams.delete('serialNumberContains')
    } else {
      newParams.set('serialNumberContains', serialNumber)
    }
    newParams.set('page', '1')
    setSearchParams(newParams.toString())
  }

  const sortDirection = (searchParams.get('sortDir') || 'DESC') as 'DESC' | 'ASC'
  const sortKey = searchParams.get('sortKey') || 'updated_at'

  return (
    <div className="card">
      <LoadingOverlay isLoading={isLoading} />

      <div className="flex" style={{ margin: '.5em', padding: '.5em' }}>
        <input
          type="text"
          placeholder="Search by serial number"
          value={serialNumberInput}
          onKeyDown={handleInputKeyDown}
          onChange={(e) => setSerialNumberInput(e.target.value)}
          style={{ marginRight: '.5em' }}
        />
        <button
          className="btn btn-submit btn-primary-dark"
          style={{ minHeight: '2.5em', backgroundColor: 'var(--colors-blue-light)' }}
          onClick={() => updateSerialNumberSearch(serialNumberInput)}
        >
          Search
        </button>
      </div>

      <table className="table dsmd-table">
        <thead>
          <tr>
            <SortableHeader
              name="Serial"
              className={'w-8 min-w-7 max-w-20'}
              sort_direction={sortDirection}
              onClick={() => setSortKey('serial_number')}
              active_sorting={sortKey === 'serial_number'}
            />
            <SortableHeader
              name="ALE"
              className={'w-5 max-w-5'}
              sort_direction={sortDirection}
              onClick={() => setSortKey('ale')}
              active_sorting={sortKey === 'ale'}
            />
            <SortableHeader
              name="DPG"
              className={'w-5 max-w-5'}
              sort_direction={sortDirection}
              onClick={() => setSortKey('dpg')}
              active_sorting={sortKey === 'dpg'}
            />
            <SortableHeader
              name="Last Updated"
              className={'w-10 min-w-10 max-w-10'}
              sort_direction={sortDirection}
              onClick={() => setSortKey('updated_at')}
              active_sorting={sortKey === 'updated_at'}
            />
            <SortableHeader
              name="Details"
              className={'w-full'}
              sort_direction={sortDirection}
              onClick={() => setSortKey('details')}
              active_sorting={sortKey === 'details'}
            />
          </tr>
        </thead>
        <tbody>
          {response?.items?.map((dsmdKeyItem) => {
            return <TableRow dsmdKeyItem={dsmdKeyItem} />
          })}
        </tbody>
      </table>

      <PagedTableControls
        currentPage={parseInt(searchParams.get('page') || `${defaultPage}`)}
        numOfItems={response?.total || 0}
        recordsPerPage={parseInt(searchParams.get('size') || `${defaultSize}`)}
        onPageUpdate={setPage}
        recordsPerPageOptions={paginationOptions}
        onRecordsPerPageChange={setPageSize}
      />

      <div style={{ marginBottom: '4em' }}>
        <h2 className="text-2xl font-bold" style={{ marginLeft: '24px' }}>
          Legend
        </h2>
        <table className="table dsmd-table">
          <thead>
            <tr style={{ height: '0px' }}>
              <InvisibleHeader className={'w-4 max-w-5'}></InvisibleHeader>
              <InvisibleHeader className={'w-11/12'}></InvisibleHeader>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <RefreshIcon title={'in progress'} width="1rem" height="1rem" color="var(--colors-yellow)" />
              </td>
              <td>In progress</td>
            </tr>
            <tr>
              <td>
                <CheckmarkIcon title={'OK'} width="1rem" height="1rem" color="var(--colors-green)" />
              </td>
              <td>OK</td>
            </tr>
            <tr>
              <td>
                <CloseIcon title={'Not OK'} width="1rem" height="1rem" color="var(--colors-red)" />
              </td>
              <td>Not OK</td>
            </tr>
            <tr>
              <td>
                <NoWifiIcon title={'Not connected'} width="1rem" height="1rem" color="var(--colors-red)" />
              </td>
              <td>Not connected</td>
            </tr>
            <tr>
              <td>
                <TimeIcon title={'Waiting'} width="1rem" height="1rem" color="var(--colors-blue)" />
              </td>
              <td>Waiting for RMV to install key</td>
            </tr>
            <tr>
              <td>ALE</td>
              <td>Installation status of private key to Ausleseeinheit (ALE)</td>
            </tr>
            <tr>
              <td>DPG</td>
              <td>Installation status of DPG CA-list</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

const InvisibleHeader = styled.th`
  height: 0;
  margin: 0;
  padding: 0 !important;
`
