export type SuccessResponse<T> = {
  data: T
}

export type PagedResponse = {
  items: DsmdKeyItem[]
  page: number
  total: number
  pageSize: number
}

export type DsmdKeyItem = {
  installationId: string
  serialNumber: string
  oneRingId?: string
  aleCredentials?: DsmdCredentials
  dpgCredentials?: DsmdCredentials
  updatedAt: string
}

export type DsmdCredentials = {
  keyId: string
  keyType: KeyType
  hash: string
  privateKey?: string
  status: InstallationStatus
  content: string
  errorDetails?: string
  createdAt: string
  updatedAt: string
}

export type InstallationStatus =
  | InstallationStatusValue.INITIALIZED
  | InstallationStatusValue.OK
  | InstallationStatusValue.PROCESSING
  | InstallationStatusValue.NOT_OK

export enum InstallationStatusValue {
  INITIALIZED = 'INITIALIZED',
  OK = 'OK',
  PROCESSING = 'PROCESSING',
  NOT_OK = 'NOT_OK',
}

export type KeyType = KeyTypeValue.ALE | KeyTypeValue.DPG

export enum KeyTypeValue {
  ALE = 'ALE',
  DPG = 'DPG',
}
