type EnvironmentConfig = {
  ENV: string
  STAGE: string
  VERSION: string
  CONFIG_BY_STAGE: { [key: string]: { CONFIG_BY_ENV: { [key: string]: any } } }
}

// @ts-ignore
const envConfig = window.__ENVIRONMENT__ as EnvironmentConfig

const { ENV: ENVIRONMENT, STAGE, VERSION, CONFIG_BY_STAGE } = envConfig
const { KEYCLOAK_HOST, API_HOST, LOCALE, TIME_ZONE } = CONFIG_BY_STAGE[STAGE].CONFIG_BY_ENV[ENVIRONMENT]
const ENV_NAME = `${ENVIRONMENT}-${STAGE}`

export { ENVIRONMENT, ENV_NAME, KEYCLOAK_HOST, API_HOST, VERSION, LOCALE, TIME_ZONE }
